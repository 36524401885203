import randomSketch from "../../../../content/blog/assets/homepage-redesign-post/random-sketch.jpg";
import part1Sketch from "../../../../content/blog/assets/homepage-redesign-post/part1-sketch.jpg";
import part1Screenshot from "../../../../content/blog/assets/homepage-redesign-post/part1-screenshot.png";
import sassyEmoji from "../../../../content/blog/assets/homepage-redesign-post/sassy-emoji.png";
import part2Sketch from "../../../../content/blog/assets/homepage-redesign-post/part2-sketch.jpg";
import part2Screenshot from "../../../../content/blog/assets/homepage-redesign-post/part2-screenshot.png";
import part3Sketch from "../../../../content/blog/assets/homepage-redesign-post/part3-sketch.jpg";
import part3Screenshot from "../../../../content/blog/assets/homepage-redesign-post/part3-screenshot.png";
import * as React from 'react';
export default {
  randomSketch,
  part1Sketch,
  part1Screenshot,
  sassyEmoji,
  part2Sketch,
  part2Screenshot,
  part3Sketch,
  part3Screenshot,
  React
};
import timegridMoreLinkClosed from "../../../../content/blog/assets/timegrid-more-link-closed.png";
import timegridMoreLinkOpen from "../../../../content/blog/assets/timegrid-more-link-open.png";
import timelineMoreLinkClosed from "../../../../content/blog/assets/timeline-more-link-closed.png";
import timelineMoreLinkOpen from "../../../../content/blog/assets/timeline-more-link-open.png";
import timegridEventsBad from "../../../../content/blog/assets/timegrid-events-bad.png";
import timegridEventsGood from "../../../../content/blog/assets/timegrid-events-good.png";
import * as React from 'react';
export default {
  timegridMoreLinkClosed,
  timegridMoreLinkOpen,
  timelineMoreLinkClosed,
  timelineMoreLinkOpen,
  timegridEventsBad,
  timegridEventsGood,
  React
};
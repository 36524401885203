import multiMonth3x4 from "../../../../content/blog/assets/multimonth-3x4.png";
import multiMonth2x6 from "../../../../content/blog/assets/multimonth-2x6.png";
import multiMonthYear from "../../../../content/blog/assets/multimonthyear.png";
import dayGridYear from "../../../../content/blog/assets/daygridyear.png";
import * as React from 'react';
export default {
  multiMonth3x4,
  multiMonth2x6,
  multiMonthYear,
  dayGridYear,
  React
};
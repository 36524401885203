exports.components = {
  "component---src-layouts-blog-index-tsx": () => import("./../../../src/layouts/blogIndex.tsx" /* webpackChunkName: "component---src-layouts-blog-index-tsx" */),
  "component---src-layouts-blog-post-tsx": () => import("./../../../src/layouts/blogPost.tsx" /* webpackChunkName: "component---src-layouts-blog-post-tsx" */),
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-commercial-license-deal-id-tsx": () => import("./../../../src/pages/commercial-license/[dealId].tsx" /* webpackChunkName: "component---src-pages-commercial-license-deal-id-tsx" */),
  "component---src-pages-commercial-license-index-tsx": () => import("./../../../src/pages/commercial-license/index.tsx" /* webpackChunkName: "component---src-pages-commercial-license-index-tsx" */),
  "component---src-pages-contact-sales-tsx": () => import("./../../../src/pages/contact-sales.tsx" /* webpackChunkName: "component---src-pages-contact-sales-tsx" */),
  "component---src-pages-contributing-mdx": () => import("./../../../src/pages/contributing.mdx" /* webpackChunkName: "component---src-pages-contributing-mdx" */),
  "component---src-pages-donate-mdx": () => import("./../../../src/pages/donate.mdx" /* webpackChunkName: "component---src-pages-donate-mdx" */),
  "component---src-pages-getting-help-mdx": () => import("./../../../src/pages/getting-help.mdx" /* webpackChunkName: "component---src-pages-getting-help-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issues-tsx": () => import("./../../../src/pages/issues.tsx" /* webpackChunkName: "component---src-pages-issues-tsx" */),
  "component---src-pages-license-mdx": () => import("./../../../src/pages/license.mdx" /* webpackChunkName: "component---src-pages-license-mdx" */),
  "component---src-pages-opportunities-tsx": () => import("./../../../src/pages/opportunities.tsx" /* webpackChunkName: "component---src-pages-opportunities-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-purchase-complete-tsx": () => import("./../../../src/pages/purchase-complete.tsx" /* webpackChunkName: "component---src-pages-purchase-complete-tsx" */),
  "component---src-pages-purchase-deal-id-tsx": () => import("./../../../src/pages/purchase/[dealId].tsx" /* webpackChunkName: "component---src-pages-purchase-deal-id-tsx" */),
  "component---src-pages-purchase-index-tsx": () => import("./../../../src/pages/purchase/index.tsx" /* webpackChunkName: "component---src-pages-purchase-index-tsx" */),
  "component---src-pages-reduced-test-cases-mdx": () => import("./../../../src/pages/reduced-test-cases.mdx" /* webpackChunkName: "component---src-pages-reduced-test-cases-mdx" */),
  "component---src-pages-renew-deal-id-tsx": () => import("./../../../src/pages/renew/[dealId].tsx" /* webpackChunkName: "component---src-pages-renew-deal-id-tsx" */),
  "component---src-pages-reporting-bugs-mdx": () => import("./../../../src/pages/reporting-bugs.mdx" /* webpackChunkName: "component---src-pages-reporting-bugs-mdx" */),
  "component---src-pages-requesting-features-mdx": () => import("./../../../src/pages/requesting-features.mdx" /* webpackChunkName: "component---src-pages-requesting-features-mdx" */),
  "component---src-pages-roadmap-mdx": () => import("./../../../src/pages/roadmap.mdx" /* webpackChunkName: "component---src-pages-roadmap-mdx" */),
  "component---src-pages-update-billing-tsx": () => import("./../../../src/pages/update-billing.tsx" /* webpackChunkName: "component---src-pages-update-billing-tsx" */)
}


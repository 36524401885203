import React from 'react'

type CodepenDemoProps = {
  codepenDataUrl: string
  label: string
}

type CodepenPrefillOptions = {
  title?: string
  description?: string
  private?: boolean // When the Pen is saved, it will save as Private if logged in user has that privledge, otherwise it will save as public
  parent?: string // If supplied, the Pen will save as a fork of this id. Note it's not the slug, but ID. You can find the ID of a Pen with `window.CP.pen.id` in the browser console.
  tags?: string[]
  editors?: string // Set which editors are open
  layout?: 'top' | 'left' | 'right'
  html: string
  html_pre_processor?: 'none' | 'slim' | 'haml' | 'markdown'
  css: string
  css_pre_processor?: 'none' | 'less' | 'scss' | 'sass' | 'stylus'
  css_starter?: 'normalize' | 'reset' | 'neither'
  css_prefix?: 'autoprefixer' | 'prefixfree' | 'neither'
  js: string
  js_pre_processor?: 'none' | 'coffeescript' | 'babel' | 'livescript' | 'typescript'
  html_classes?: string
  head?: string
  css_external?: string // semi-colon separate multiple files
  js_external?: string // semi-colon separate multiple files
}

export const CodepenDemo: React.FC<CodepenDemoProps> = ({ codepenDataUrl, label }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    const newWindow = window.open('', '_blank')
    if (!newWindow) return

    getUrlContent(codepenDataUrl, (response) => {
      const codepenData = buildCodepenData(response, codepenDataUrl)

      newWindow.document.open()
      newWindow.document.write(`
<html>
  <body>
    <form action="https://codepen.io/pen/define" method="POST">
      <input type="hidden" name="data" value="${reformatAsValue(codepenData)}" />
    </form>
    <script>document.forms[0].submit()</script>
  </body>
</html>
      `)
      newWindow.document.close()
    })
  }
  return (
    <a target='_blank' href={codepenDataUrl} rel='noreferrer' onClick={handleClick}>
      {label}
    </a>
  )
}

const getUrlContent = (url: string, callback: (response: string) => void) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.onload = () => {
    if (xhr.status === 200) {
      callback(xhr.responseText)
    }
  }
  xhr.send()
}

const settings = {
  collapseHtml: true,
  collapseCss: true,
  collapseJs: false,
  filterJsUrl: (url: string) => {
    return !url.match(/\/demo-to-codepen\.js$/)
  },
  filterCssUrl: (url: string) => {
    return !url.match(/\/demo-to-codepen\.css$/)
  },
  filterJs: (js: string) => {
    return js
  },
  filterCss: (css: string) => {
    return css.replace(/\.demo-topbar[^{]*\{[^}]*?\}/g, '')
  },
  filterHtml: (html: string) => {
    return html
      .replace(/<div[^>]+class\s*=\s*['"]demo-topbar['"][\s\S]*?<\/\s*div\s*>/gi, '')
      .replace(/<(a|button)[^>]+data-codepen[^>]*>[\s\S]*?<\/\s*\1\s*>/gi, '')
  },
}

const buildCodepenData = (html: string, baseUrl: string): CodepenPrefillOptions => {
  const BODY_RE = /<body([^>]*)>([\s\S]*)<\/body>/
  const SCRIPT_RE = /<script([^>]*)>([\s\S]*?)<\/script>/g
  const LINK_RE = /<link([^>]*)>/g
  const STYLE_RE = /<style([^>]*)>([\s\S]*?)<\/style>/g
  let bodyHtml = ''
  const inlineJsBlocks = []
  const inlineCssBlocks = []
  const jsUrls = []
  const cssUrls = []
  let match
  let url
  let code

  if ((match = BODY_RE.exec(html))) {
    bodyHtml = normalizeCode(absolutizeHtmlRefs(settings.filterHtml(match[2]), baseUrl))
  }

  while ((match = SCRIPT_RE.exec(html))) {
    url = parseAttribute(match[1], 'src')

    if (url) {
      jsUrls.push(normalizeUrl(url, baseUrl))
    } else if ((code = normalizeCode(match[2]))) {
      code = settings.filterJs(code)
      inlineJsBlocks.push(code)
    }
  }

  while ((match = LINK_RE.exec(html))) {
    if (
      parseAttribute(match[1], 'rel') === 'stylesheet' &&
      parseAttribute(match[1], 'media') !== 'print' && // exclude print-only stylesheets
      (url = parseAttribute(match[1], 'href'))
    ) {
      cssUrls.push(normalizeUrl(url, baseUrl))
    }
  }

  while ((match = STYLE_RE.exec(html))) {
    code = match[2]
    code = settings.filterCss(code)
    code = normalizeCode(code)

    if (code) {
      inlineCssBlocks.push(code)
    }
  }

  return {
    css: inlineCssBlocks.join('\n\n'),
    js: inlineJsBlocks.join('\n\n'),
    html: bodyHtml,
    js_external: jsUrls.filter(settings.filterJsUrl).join(';'),
    css_external: cssUrls.filter(settings.filterCssUrl).join(';'),
    editors:
      (!settings.collapseHtml && bodyHtml ? '1' : '0') +
      (!settings.collapseCss && inlineCssBlocks.length ? '1' : '0') +
      (!settings.collapseJs && inlineJsBlocks.length ? '1' : '0'),
  }
}

const parseAttribute = (attrStr: string, attrName: string) => {
  const re = new RegExp(attrName + 's*=s*[\\\'"]([^\\\'"]+)[\\\'"]')
  const match = re.exec(attrStr)
  if (match) return match[1]
}

const normalizeUrl = (href: string, baseUrl: string) => {
  const HOST_RE = /^(\w+:\/\/([^/]+))(.*)$/ // also matches the protocol like https://

  if (href.match(HOST_RE)) {
    // already a fully-formed absolute URL
    return href
  }

  const baseHostMatch = baseUrl.match(HOST_RE)
  if (!baseHostMatch) return href

  if (href.charAt(0) === '/') {
    // a URL with a starting slash
    return baseHostMatch[1] + href
  }

  // derived from https://stackoverflow.com/a/14780463/96342
  const stack = baseHostMatch[2].split('/')
  const parts = href.split('/')
  stack.pop()
  for (let i = 0; i < parts.length; i++) {
    if (parts[i] === '.') continue
    if (parts[i] === '..') stack.pop()
    else stack.push(parts[i])
  }

  return baseHostMatch[1] + stack.join('/')
}

const absolutizeHtmlRefs = (html: string, baseUrl: string) => {
  return html.replace(/(src|href)(\s*=\s*['"])([^'"]*)(['"])/g, function (m0, m1, m2, m3, m4) {
    return m1 + m2 + normalizeUrl(m3, baseUrl) + m4
  })
}

const normalizeCode = (code: string) => {
  code = code.replace(/[\t ]+$/gm, '') // strip trailing whitespace
  code = code.replace(/\n{2,}/g, '\n\n') // strip consecutive blank lines

  const commonIndent = computeCommonIndent(code)

  // remove the common indent from all lines
  if (commonIndent) {
    code = code.replace(new RegExp('^' + commonIndent, 'mg'), '')
  }
  return code.trim()
}

const computeCommonIndent = (s: string) => {
  const RE = /^[\t ]+/gm // specifically test for spaces/tabs to avoid matching newlines
  const indents = []
  let match
  let smallestIndentLen = 1000

  while ((match = RE.exec(s))) {
    const indent = match[0]

    if (indent) {
      indents.push(indent)
      smallestIndentLen = Math.min(smallestIndentLen, indent.length)
    }
  }

  if (indents.length) {
    const indent = indents[0].substr(0, smallestIndentLen)

    for (let i = 1; i < indents.length; i++) {
      if (indents[i].substr(0, smallestIndentLen) !== indent) {
        return ''
      }
    }

    return indent
  }

  return ''
}

const reformatAsValue = (options: CodepenPrefillOptions) => {
  return JSON.stringify(options)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#039;')
    .replace(/"/g, '&quot;')
    .replace(/\n/g, '<br />')
}

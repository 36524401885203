import yc from "../../../../content/blog/assets/10-year/yc.svg";
import ga from "../../../../content/blog/assets/10-year/ga.png";
import hustleAndBustle from "../../../../content/blog/assets/10-year/hustle-and-bustle.jpg";
import momentjs from "../../../../content/blog/assets/10-year/momentjs.png";
import karma from "../../../../content/blog/assets/10-year/karma.png";
import newSite from "../../../../content/blog/assets/10-year/newsite.png";
import shutup from "../../../../content/blog/assets/10-year/shut-up.jpg";
import beer from "../../../../content/blog/assets/10-year/beer.jpg";
import react from "../../../../content/blog/assets/10-year/react.svg";
import vue from "../../../../content/blog/assets/10-year/vue.png";
import angular from "../../../../content/blog/assets/10-year/angular.svg";
import cake from "../../../../content/blog/assets/10-year/cake.jpg";
import * as React from 'react';
export default {
  yc,
  ga,
  hustleAndBustle,
  momentjs,
  karma,
  newSite,
  shutup,
  beer,
  react,
  vue,
  angular,
  cake,
  React
};
import moreBeforeOpen from "../../../../content/blog/assets/more_before_open.gif";
import moreAfterOpen from "../../../../content/blog/assets/more_after_open.gif";
import printingExample from "../../../../content/blog/assets/printing_example.gif";
import noJqui from "../../../../content/blog/assets/no_jqui.gif";
import * as React from 'react';
export default {
  moreBeforeOpen,
  moreAfterOpen,
  printingExample,
  noJqui,
  React
};